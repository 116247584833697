<template>
  <div class="box box--md box--white">
    <table class="table table--list three-items">
      <tbody>
        <tr>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Amount in TOKO</p>
              <h2 class="headline--md mt-4">{{ reportReferrer.credit_toko }}</h2>
            </div>
          </td>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Amount in USD</p>
              <h2 class="headline--md mt-4">{{ reportReferrer.credit_usd }}</h2>
            </div>
          </td>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Users used the referral link</p>
              <h2 class="headline--md mt-4">{{ reportReferrer.user_referred }}</h2>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="divider divider--expand-24 mt-24 mb-16"></div>
    <slot name="searchBox" />

    <div v-if="reportReferredUsers" class="table-wrapper table-wrapper--fluid table-wrapper--expand mt-12">
      <table class="table table--striped">
        <thead>
          <tr>
            <th>Acc. ID</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Mobile Number</th>
            <th>Joined Date</th>
            <th>Trans. Hash</th>
            <th>Black List</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(referred, index) in reportReferredUsers.users" :key="index" class="link--clone">
            <td @click="$router.open({ name: 'User', params: { id: referred.user.id } })">{{ referred.user.id }}</td>
            <td @click="$router.open({ name: 'User', params: { id: referred.user.id } })">
              {{ referred.user.full_name }}
            </td>
            <td @click="$router.open({ name: 'User', params: { id: referred.user.id } })">{{ referred.user.email }}</td>
            <td @click="$router.open({ name: 'User', params: { id: referred.user.id } })">
              {{ referred.user.country_code }}{{ referred.user.phone }}
            </td>
            <td
              @click="$router.open({ name: 'User', params: { id: referred.user.id } })"
              v-html="getMomentLTSFormatted(referred.user.created_at)"
            />
            <router-link tag="td" class="wr-25 link--primary link--underline" :to="`/etherscan/${referred.tnx_hash}`">
              <p class="text-ellipsis link--clone">{{ referred.tnx_hash }}</p>
            </router-link>
            <td @click="$router.open({ name: 'User', params: { id: referred.user.id } })" class="text-bold text-error">
              {{ referred.user.blacklist | format_blacklist_type }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ reportReferredUsers.total | format_user_count_text }} in total</p>

        <slot name="pagination" />
      </div>
    </div>
  </div>
</template>

<script>
import momentltsFormat from '@/mixins/momentlts-format'

export default {
  name: 'ReferralInformation',

  mixins: [momentltsFormat],

  props: {
    reportReferrer: {
      type: Object,
      require: true
    },

    reportReferredUsers: {
      type: Object,
      require: false
    }
  }
}
</script>
